import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './flowDiagram.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {graphql, Link, useStaticQuery} from "gatsby";
import {getRoutes} from "../../shared/routes";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_flow_diagram"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const FlowDiagram = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();

    // const linkToMobileApps = `${routes.Solutions.route}#${routes.Solutions.sections.mobileApps.anchor}`;
    // const linkToSaas = `${routes.Solutions.route}#${routes.Solutions.sections.saas.anchor}`;

    const links = <>
        <div className={[css.contentLink, css.contentLink1].join(' ')}>
            {/*<Link to={routes.SolutionsDMH.route}>Applying Code Marking</Link>*/}
        </div>
        <div className={[css.contentLink, css.contentLink2].join(' ')}>
            {/*<a href={linkToMobileApps}>Image Shooting</a>*/}
        </div>
        <div className={[css.contentLink, css.contentLink3].join(' ')}>
            {/*<a href={linkToMobileApps}>Image Sourcing</a>*/}
        </div>
        <div className={[css.contentLink, css.contentLink4].join(' ')}>
            {/*<a href={linkToMobileApps}>Mobile App</a>*/}
        </div>
        <div className={[css.contentLink, css.contentLink5].join(' ')}>
            {/*<a href={linkToSaas}>Web Client</a>*/}
        </div>
    </>;

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperBg}/>
            <div className={css.content}>
                <div className={css.title}>Flow Diagram</div>
                <div className={css.bigContent}>
                    {renderImageByFileName(imagesFluid, 'Flow_Diagram.png', '')}
                    <div className={[css.contentLinks, css.bigContentLinks].join(' ')}>
                        {links}
                    </div>
                </div>
                <div className={css.smallContent}>
                    {renderImageByFileName(imagesFluid, 'Flow_Diagram_mobile.png', '')}
                    <div className={[css.contentLinks, css.smallContentLinks].join(' ')}>
                        {links}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlowDiagram;

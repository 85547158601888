import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getLink, getRoutes} from '../components/shared/routes';
import MobileApps from "../components/sections/mobile_apps/MobileApps";
import Saas from "../components/sections/saas/Saas";
import Devices from "../components/sections/Devices/Devices";
import FlowDiagram from "../components/sections/FlowDiagram/FlowDiagram";
import SolutionsSplash from "../components/sections/SolutionsSplash/SolutionsSplash";
import AdditionalInformation from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import RedirectTo from "../components/shared/RedirectTo/RedirectTo";


export default () => (<RedirectTo url={getLink([getRoutes().Technology])}/>);
// const Forestry = () => {
//     const routes = getRoutes().Solutions;
//     const {sections} = routes;
//     const pageTitle = routes.pageTitle;
//
//     return (
//         <Layout>
//             <SEO title={pageTitle}/>
//
//             <PageTitle>{pageTitle}</PageTitle>
//
//             <SolutionsSplash/>
//
//             <FlowDiagram/>
//
//             <div id={routes.sections.saas.anchor}>
//                 <Saas/>
//             </div>
//
//             <div id={routes.sections.mobileApps.anchor}>
//                 <MobileApps/>
//             </div>
//
//             <div id={sections.dcodeMarking.anchor}>
//                 <Devices/>
//             </div>
//
//             <AdditionalInformation/>
//
//             <Footer/>
//         </Layout>
//     );
// };
//
// export default Forestry;
